import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedProvince: 'Ontario',
};

const provinceSlice = createSlice({
    initialState,
    name: 'settings',
    reducers: {
        setProvince: (state, action) => {
            state.selectedProvince = action.payload;
        },
    },
});

export const { setProvince } = provinceSlice.actions;

export default provinceSlice.reducer;

// Selectors

const getSelf = state => state.settings;

export const getSelectedProvince = createSelector(
    getSelf,
    settings => settings.selectedProvince,
);
