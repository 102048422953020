export const apiRoutes = {
    auth: {
        local: 'auth/local',
    },
    users: '/api/v2/users',
    skills: '/api/v2/users/skills',
    certificates: '/api/v2/certificates',
    jobs: '/api/v3/jobs',
    entities: '/api/v2/entities',
    searchUsers: '/api/users/searchAllStaffers/all',
    searchEntities: '/api/v2/entities/search',
    uploadProfile: id => `/api/v2/users/${id}/upload/profileUrl`,
    getSkills: '/api/industries',
    getSubSkills: '/api/industries/subSkill/list',
    blockUser: '/api/v2/block-list',
    addSkill: userId => `api/v2/users/${userId}/skills`,
    skill: skillId => `api/v2/users/skills/${skillId}`,
    subSkill: userId => `api/v2/users/${userId}/sub-skills`,
    addReference: userId => `/api/v2/users/${userId}/references`,
    updateReference: refId => `/api/v2/users/references/${refId}`,
    deleteReference: refId => `/api/v2/users/references/${refId}`,
    deleteCertificate: (userId, certId) =>
        `/api/certificates/deleteCertificate/${userId}/${certId}`,
    sendNotification: '/api/users/sendNotificationToUser/everyone',
    sendEmail: '/api/users/sendEmail/withText',
    sendSMS: userId => `/api/users/sendSMSToStaffer/${userId}`,
    addNote: userId => `/api/users/writeNote/${userId}`,
    certificateLogs: userId =>
        `api/userLogs/fetchStafferCertificateLog/${userId}`,
    availabilityLogs: userId =>
        `api/availabilityLog/fetchSpecificStafferLogs/${userId}`,
    smsHistory: '/api/sms/twilioSMSHistory',
    receiveSMSHistory: '/api/sms/receiveTwilioSMSHistory',
    uploadResume: userId => `api/v2/users/${userId}/upload/resumeUrl`,
    sendResetPasswordEmail: 'api/appInfo/forgotPassword',
    uploadUniformPhoto: (id, type) => `/api/v2/users/${id}/upload/${type}`,
    updateUniformPhotoStatus: (id, type) =>
        `/api/v2/users/${id}/uniform-picture/${type}/update-status`,
    resetQuiz: userId => `/api/users/resetQuiz/${userId}`,
    acceptContractor: userId =>
        `/api/v2/users/${userId}/update-status/approve-profile`,
    rejectContractor: userId =>
        `/api/v2/users/${userId}/update-status/deactivate-profile`,
    blockContractor: userId => `/api/v2/users/${userId}/update-status/block`,
    putContractorUnderReview: userId =>
        `/api/v2/users/${userId}/update-status/under-review`,
    rejectedUser: '/api/rejected-user',
    restoreUser: userId => `/api/rejected-user/${userId}/restore`,
    restoreContractor: userId => `/api/users/markRestoreUser/${userId}`,
    userBlocklist: '/api/v2/block-list',
    stopUnPaidInvoices: userId =>
        `api/instantPayment/stopAllUnpaidInvoicesForTheStaffer/${userId}`,
    removedFromUpcomingShifts: userId =>
        `api/jobs/admin/removeStafferFromUpcomingShifts/${userId}`,
    switchToETransfer: () => '/api/berkeleyPayment/e-transfer/setup',
    syncETransferEmail: () => '/api/berkeleyPayment/e-transfer/update',
    checkStafferAvailability: userId =>
        `/api/v2/users/${userId}/check-availability`,
    approveCertificate: certificateId =>
        `/api/v2/certificates/${certificateId}/approve`,
    rejectCertificate: certificateId =>
        `/api/v2/certificates/${certificateId}/reject`,
};
