import { combineReducers } from '@reduxjs/toolkit';
import uiReducer from './ui';
import authReducer from './auth';
import settingsReducer from './user-settings';
import talentReducer from './talent';
import shiftReducer from './shifts';

export default combineReducers({
    ui: uiReducer,
    auth: authReducer,
    settings: settingsReducer,
    talent: talentReducer,
    shifts: shiftReducer,
});
