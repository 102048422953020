import axios from 'axios';
import store from 'src/store';
import { logout, userAuthRequestFailed } from 'src/store/auth';
import { showToastAction } from 'src/store/ui';

let responseInterceptorId;

export const configureNetworkSettingsForAuthenticatedUser = () => {
    setHeader();
    setResponseInterceptor();
};

// AUTH HEADER WITH EVERY REQUEST
const setHeader = () => {
    const authState = store.getState().auth;
    axios.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${authState.token}`;
};

// response interceptor
const setResponseInterceptor = () => {
    // we have to use type of because the id can be 0
    if (typeof responseInterceptorId != 'undefined') {
        // interceptor already created, this method can be called twice
        // incase there is any error in DOM
        // probably not gonna happen in prod
        return;
    }

    responseInterceptorId = axios.interceptors.response.use(
        res => res,
        err => {
            logNetworkError(err);

            if (err.response.status === 401) {
                store.dispatch(
                    userAuthRequestFailed(
                        'You are logged out because your session has expired. Kindly log in again',
                    ),
                );
                store.dispatch(logout());
            } else {
                store.dispatch(
                    showToastAction({
                        type: 'danger',
                        title: 'Error',
                        text: generateErrorText(err),
                    }),
                );
            }

            return Promise.reject(err);
        },
    );
};

// this will be called from logout side effect
export const cancelAxiosResponseInterceptor = () => {
    axios.interceptors.response.eject(responseInterceptorId);
    responseInterceptorId = null;
};

const logNetworkError = ({ response }) => {
    const message = `Call to ${response.config.baseURL || ''}${
        response.config.url || ''
    } failed with status code ${response.status}`;
    console.log(message);
};

const generateErrorText = ({ response }) => {
    if (!response) {
        return 'something went wrong';
    }

    if (!response.data) {
        return response.statusText;
    }

    if (typeof response.data === 'string') {
        return response.data;
    }

    if (
        typeof response.data === 'object' &&
        typeof response.data.message === 'string'
    ) {
        return response.data.message;
    }

    return 'something went wrong';
};
