import { call, put, takeEvery } from 'redux-saga/effects';
import { SendApiRequest } from 'src/api';
import { apiRoutes } from 'src/api/routes';
import {
    fetchShifts,
    shiftsFetched,
    shiftsFetchRequestFailed,
} from '../shifts';

function* fetchShiftsEffect({ payload }) {
    try {
        const response = yield call(SendApiRequest, {
            url: apiRoutes.jobs,
            method: 'GET',
            queryParams: { ...payload, preset: 'upcoming_ongoing' },
        });
        yield put(
            shiftsFetched({
                results: response.results,
                totalRecords: response.totalRecords,
            }),
        );
    } catch (e) {
        yield put(shiftsFetchRequestFailed());
    }
}

export const shiftsSagas = [takeEvery(fetchShifts.type, fetchShiftsEffect)];
