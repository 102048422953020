import { call, put, takeEvery } from 'redux-saga/effects';
import {
    authenticateUser as authenticateUserAction,
    authRequestSent,
    userAuthRequestFailed,
    userAuthenticated,
} from '../auth';
import { authenticateUser } from '../../api/auth';

function* performUserAuthentication(action) {
    try {
        yield put(authRequestSent());
        const authResponse = yield call(authenticateUser, action.payload);
        yield put(userAuthenticated(authResponse));
    } catch (e) {
        yield put(userAuthRequestFailed(e.message));
    }
}

export const authSagas = [
    takeEvery(authenticateUserAction.type, performUserAuthentication),
];
