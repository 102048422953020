import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { SendApiRequest } from 'src/api';
import { apiRoutes } from 'src/api/routes';
import {
    contractorsFetched,
    contractorsFetchRequestFailed,
    fetchContractors,
    contractorUpdated,
    updateContractor,
    updateSkill,
    skillUpdated,
    skillRequestFailed,
    removeSkill,
    skillRemoved,
    createSkill,
    skillCreated,
    addSubSkill,
    subSkillAdded,
    remvoeSubSkill,
    subSkillRemoved,
} from '../talent';
import { showToastAction } from '../ui';
import { getSelectedProvince } from '../user-settings';

function* fetchContractorsEffect({ payload }) {
    // Select the province from the Redux store
    const selectedProvince = yield select(getSelectedProvince);
    try {
        const response = yield call(SendApiRequest, {
            url: apiRoutes.users,
            method: 'GET',
            queryParams: { ...payload, province: selectedProvince },
        });
        yield put(
            contractorsFetched({
                results: response.results,
                totalRecords: response.totalRecords,
            }),
        );
    } catch (e) {
        yield put(contractorsFetchRequestFailed());
    }
}

function* updateContractorEffect({ payload }) {
    try {
        const { userId, successMessage } = payload;
        const response = yield call(SendApiRequest, {
            url: `${apiRoutes.users}/${userId}`,
            method: 'GET',
        });
        console.log(response);
        yield put(contractorUpdated(response));
        yield put(
            showToastAction({
                type: 'success',
                text: successMessage || 'Contractor updated successfully',
            }),
        );
    } catch (e) {
        yield put(contractorsFetchRequestFailed());
    }
}

function* createSkillEffect({ payload }) {
    try {
        const { contractorId, skill } = payload;
        const response = yield call(SendApiRequest, {
            url: apiRoutes.addSkill(contractorId),
            method: 'POST',
            body: { skill },
        });
        yield put(showToastAction({ type: 'success', text: response.message }));
        yield put(skillCreated({ contractorId, skill: response.skill }));
    } catch (e) {
        yield put(skillRequestFailed());
    }
}

function* updateSkillEffect({ payload }) {
    try {
        const { contractorId, skillId, update } = payload;
        const response = yield call(SendApiRequest, {
            url: apiRoutes.skill(skillId),
            method: 'PUT',
            body: update,
        });
        yield put(showToastAction({ type: 'success', text: response.message }));
        yield put(skillUpdated({ contractorId, skillId, update }));
    } catch (e) {
        yield put(skillRequestFailed());
    }
}

function* removeSkillEffect({ payload }) {
    try {
        const { contractorId, skillId } = payload;
        const response = yield call(SendApiRequest, {
            url: apiRoutes.skill(skillId),
            method: 'DELETE',
        });
        yield put(showToastAction({ type: 'success', text: response.message }));
        yield put(skillRemoved({ contractorId, skillId }));
    } catch (e) {
        yield put(skillRequestFailed());
    }
}

function* addSubSkillEffect({ payload }) {
    try {
        const { contractorId, subSkill } = payload;
        const response = yield call(SendApiRequest, {
            url: apiRoutes.subSkill(contractorId),
            method: 'POST',
            body: { subSkill },
        });
        yield put(showToastAction({ type: 'success', text: response.message }));
        yield put(subSkillAdded({ contractorId, subSkill }));
    } catch (e) {
        yield put(skillRequestFailed());
    }
}

function* removeSubSkillEffect({ payload }) {
    try {
        const { contractorId, subSkill } = payload;
        const response = yield call(SendApiRequest, {
            url: apiRoutes.subSkill(contractorId),
            method: 'DELETE',
            body: { subSkill },
        });
        yield put(showToastAction({ type: 'success', text: response.message }));
        yield put(subSkillRemoved({ contractorId, subSkill }));
    } catch (e) {
        console.log(e);
        yield put(skillRequestFailed());
    }
}

export const talentSagas = [
    takeEvery(fetchContractors.type, fetchContractorsEffect),
    takeEvery(updateContractor.type, updateContractorEffect),
    takeLatest(createSkill.type, createSkillEffect),
    takeLatest(updateSkill.type, updateSkillEffect),
    takeLatest(removeSkill.type, removeSkillEffect),
    takeLatest(addSubSkill.type, addSubSkillEffect),
    takeLatest(remvoeSubSkill.type, removeSubSkillEffect),
];
