import axios from 'axios';
import { apiRoutes } from './routes';

// credentials => {email, password}
export const authenticateUser = async credentials => {
    try {
        const response = await axios.request({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: apiRoutes.auth.local,
            method: 'post',
            data: credentials,
        });
        return response.data;
    } catch (e) {
        // handle known errors
        if (e.response.status === 401) {
            throw new Error('Incorrect username or password');
        }
        throw new Error(e.message);
    }
};
