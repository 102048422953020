import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    reducer,
    middleware: [
        ...getDefaultMiddleware(),
        // apiMiddleWare,
        sagaMiddleware,
    ],
    devTools: true,
});

sagaMiddleware.run(rootSaga);
