import { createSlice, createAction } from '@reduxjs/toolkit';

const presistedAuthState = localStorage.getItem('authState');
const initialState = presistedAuthState
    ? JSON.parse(presistedAuthState)
    : {
          isUserLoggedIn: false,
          token: null,
      };

const authSlice = createSlice({
    initialState: { ...initialState, loading: false, errorMessage: '' },
    name: 'auth',
    reducers: {
        authRequestSent: state => {
            state.errorMessage = '';
            state.loading = true;
        },
        userAuthenticated: (state, action) => {
            state.token = action.payload.token;
            state.isUserLoggedIn = true;
            state.loading = false;
        },
        userAuthRequestFailed: (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        logout: state => {
            state.isUserLoggedIn = false;
            state.token = null;
        },
    },
});

// for saga based auth
export const authenticateUser = createAction('auth/authenticateUser');

export const {
    authRequestSent,
    userAuthenticated,
    userAuthRequestFailed,
    logout,
} = authSlice.actions;

export default authSlice.reducer;
