import PropTypes from 'prop-types';
import { CSpinner } from '@coreui/react';
import React from 'react';
import styled from 'styled-components';

const LoaderWrapperStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
`;

export default function Loader({ size, ...props }) {
    return (
        <LoaderWrapperStyle {...props}>
            <CSpinner size={size || 'lg'} />
        </LoaderWrapperStyle>
    );
}

Loader.propTypes = {
    size: PropTypes.string,
};
