import { createSelector, createSlice } from '@reduxjs/toolkit';

const shiftsSlice = createSlice({
    initialState: {
        contractors: [],
        totalRecords: 0,
        isLoading: false,
    },
    name: 'shifts',
    reducers: {
        fetchShifts: state => {
            state.contractors = [];
            state.totalRecords = 0;
            state.isLoading = true;
        },
        shiftsFetched: (state, { payload }) => {
            const { results, totalRecords } = payload;
            state.totalRecords = totalRecords || 0;
            state.contractors = results;
            state.isLoading = false;
        },
        shiftsFetchRequestFailed: state => {
            state.isLoading = false;
        },
    },
});

// selectors
const getSelf = state => state.shifts;

export const getShifts = createSelector(getSelf, state => state.contractors);

export const isLoading = createSelector(getSelf, state => state.isLoading);

export const getTotalRecords = createSelector(
    getSelf,
    state => state.totalRecords,
);

export const { fetchShifts, shiftsFetched, shiftsFetchRequestFailed } =
    shiftsSlice.actions;

export default shiftsSlice.reducer;
