import { call, put, takeEvery } from 'redux-saga/effects';
import { SendApiRequest } from 'src/api';
import { apiRoutes } from 'src/api/routes';
import {
    contractorsFetched,
    contractorsFetchRequestFailed,
    fetchRejectedContractors,
} from '../talent';

// import { showToastAction } from '../ui';

function* fetchRejectedUserEffect({ payload }) {
    try {
        const response = yield call(SendApiRequest, {
            url: apiRoutes.rejectedUser,
            method: 'GET',
            queryParams: { ...payload },
        });
        yield put(
            contractorsFetched({
                results: response.results,
                totalRecords: response.totalRecords,
            }),
        );
    } catch (e) {
        yield put(contractorsFetchRequestFailed());
    }
}

export const rejectedUserSagas = [
    takeEvery(fetchRejectedContractors.type, fetchRejectedUserEffect),
];
