import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    initialState: {
        showToast: {
            title: '',
            text: '',
            type: '',
        },
        sidebarShow: 'responsive',
    },
    name: 'UI',
    reducers: {
        toggleSidebarAction: (state, action) => {
            state.sidebarShow = action.payload.sidebarShow;
        },
        showToastAction: (state, action) => {
            state.showToast = {
                ...action.payload,
                title: action.payload?.title || action.payload.type,
            };
        },
    },
});

export const { toggleSidebarAction, showToastAction } = uiSlice.actions;

export default uiSlice.reducer;
