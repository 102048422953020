import axios from 'axios';
import qs from 'qs';

export const SendApiRequest = async ({ url, method, queryParams, body }) => {
    url = `${url}?${qs.stringify(queryParams)}`;

    try {
        const response = await axios.request({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url,
            method,
            queryParams,
            data: body,
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
