import { all } from 'redux-saga/effects';
import { authSagas } from './auth.saga';
import { shiftsSagas } from './shifts.saga';
import { talentSagas } from './talent.saga';
import { rejectedUserSagas } from './rejected-user.saga';

export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...talentSagas,
        ...shiftsSagas,
        ...rejectedUserSagas,
    ]);
}
