import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

const talentSlice = createSlice({
    initialState: {
        filters: [],
        contractors: [],
        totalRecords: 0,
        isLoading: false,
        currentPage: 0,
    },
    name: 'talent',
    reducers: {
        fetchContractors: state => {
            state.contractors = [];
            state.isLoading = true;
        },
        fetchRejectedContractors: state => {
            state.contractors = [];
            state.isLoading = true;
        },
        contractorsFetched: (state, { payload }) => {
            const { results, totalRecords } = payload;
            state.contractors = results;
            state.totalRecords = totalRecords;
            state.isLoading = false;
        },
        contractorsFetchRequestFailed: state => {
            state.isLoading = false;
        },
        updateContractor: state => {
            state.isLoading = true;
        },
        contractorUpdated: (state, { payload }) => {
            state.contractors = state.contractors.map(c => {
                return c._id === payload._id ? payload : c;
            });
            state.isLoading = false;
        },
        contractorPartialUpdate: (state, { payload }) => {
            const { userId, updatedContractorData } = payload;
            state.contractors = state.contractors.map(contractor => {
                return contractor._id === userId
                    ? {
                          ...contractor,
                          ...updatedContractorData,
                      }
                    : contractor;
            });
        },
        removeContractor: (state, { payload }) => {
            state.contractors = state.contractors.filter(
                c => c._id !== payload.id,
            );
        },
        addIntoBlockedList: (state, { payload }) => {
            const { contractorId, blockedItem } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            state.contractors[contractorIndex].blockedList.push(blockedItem);
        },
        removeFromBlockedList: (state, { payload }) => {
            const { contractorId, entityId } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            const blockedIndex = state.contractors[
                contractorIndex
            ].blockedList.findIndex(item => item._id === entityId);

            state.contractors[contractorIndex].blockedList.splice(
                blockedIndex,
                1,
            );
        },
        removeReference: (state, { payload }) => {
            const { contractorId, refId } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            const referenceIndex = state.contractors[
                contractorIndex
            ].references.findIndex(item => item._id === refId);

            state.contractors[contractorIndex].references.splice(
                referenceIndex,
                1,
            );
        },
        skillCreated: (state, { payload }) => {
            const { contractorId, skill } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            state.contractors[contractorIndex].skills.push(skill);
        },

        skillUpdated: (state, { payload }) => {
            const { contractorId, skillId, update } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            const skillIndex = state.contractors[
                contractorIndex
            ].skills.findIndex(skill => skill._id === skillId);

            let skill = state.contractors[contractorIndex].skills[skillIndex];

            skill = {
                ...skill,
                ...update,
            };

            state.contractors[contractorIndex].skills[skillIndex] = skill;
        },

        skillRemoved: (state, { payload }) => {
            const { contractorId, skillId } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            const skillIndex = state.contractors[
                contractorIndex
            ].skills.findIndex(skill => skill._id === skillId);

            state.contractors[contractorIndex].skills.splice(skillIndex, 1);
        },

        subSkillAdded: (state, { payload }) => {
            const { contractorId, subSkill } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            state.contractors[contractorIndex].subSkills.push(subSkill);
        },
        subSkillRemoved: (state, { payload }) => {
            const { contractorId, subSkill } = payload;

            const contractorIndex = state.contractors.findIndex(
                contractor => contractor._id === contractorId,
            );

            const subSkillIndex =
                state.contractors[contractorIndex].subSkills.indexOf(subSkill);

            state.contractors[contractorIndex].subSkills.splice(
                subSkillIndex,
                1,
            );
        },
        upsertFilter: (state, { payload }) => {
            const { key, value } = payload;
            const index = state.filters.findIndex(f => f.key === key);

            // If no filter exist and value is also nothing then return
            if (index === -1 && value === '') {
                return;
            }

            if (index === -1) {
                state.filters.push(payload);
            } else {
                state.filters[index].value = value;
            }
        },
        removeFilter: (state, { payload }) => {
            const index = state.filters.findIndex(f => f.key === payload);

            if (index !== -1) {
                state.filters.splice(index, 1);
            }
        },
        removeAllFilters: state => {
            state.filters = [];
        },
    },
});

// ACTION CREATORS

export const createSkill = createAction('talent/createSkill');
export const updateSkill = createAction('talent/updateSkill');
export const removeSkill = createAction('talent/removeSkill');
export const addSubSkill = createAction('talent/addSubSkill');
export const remvoeSubSkill = createAction('talent/removeSubSkill');

export const skillRequestFailed = createAction('talent/skillRequestFailed');

// selectors

const getSelf = state => state.talent;

export const getContractors = createSelector(
    getSelf,
    state => state.contractors,
);

export const getFilters = createSelector(getSelf, state => state.filters);

export const isLoading = createSelector(getSelf, state => state.isLoading);

export const getTotalRecords = createSelector(
    getSelf,
    state => state.totalRecords,
);

export const {
    // Fetch Actions
    fetchContractors,
    fetchRejectedContractors,
    contractorsFetched,
    contractorsFetchRequestFailed,
    // Update Actions
    updateContractor,
    contractorUpdated,
    contractorPartialUpdate,
    removeContractor,
    // Skills
    skillCreated,
    skillUpdated,
    skillRemoved,
    // Sub skill
    subSkillAdded,
    subSkillRemoved,
    // Blocked User
    addIntoBlockedList,
    removeFromBlockedList,
    // References
    removeReference,
    // Filters
    upsertFilter,
    removeFilter,
    removeAllFilters,
} = talentSlice.actions;

export default talentSlice.reducer;
